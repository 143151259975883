/**
 * @license
 * MyFonts Webfont Build ID 3798079, 2019-08-16T16:12:49-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: HelveticaNowText-Medium by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/text-medium/
 * 
 * Webfont: HelveticaNowDisplay-XBd by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/display-extrabold/
 * 
 * Webfont: HelveticaNowMicro-Medium by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/micro-medium/
 * 
 * Webfont: HelveticaNowText-Bold by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/text-bold/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3798079
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2019 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/39f43f");

  
@font-face {font-family: 'HelveticaNowText-Medium';src: url('webfonts/39F43F_0_0.eot');src: url('webfonts/39F43F_0_0.eot?#iefix') format('embedded-opentype'),url('webfonts/39F43F_0_0.woff2') format('woff2'),url('webfonts/39F43F_0_0.woff') format('woff'),url('webfonts/39F43F_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'HelveticaNowDisplay-XBd';src: url('webfonts/39F43F_1_0.eot');src: url('webfonts/39F43F_1_0.eot?#iefix') format('embedded-opentype'),url('webfonts/39F43F_1_0.woff2') format('woff2'),url('webfonts/39F43F_1_0.woff') format('woff'),url('webfonts/39F43F_1_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'HelveticaNowMicro-Medium';src: url('webfonts/39F43F_2_0.eot');src: url('webfonts/39F43F_2_0.eot?#iefix') format('embedded-opentype'),url('webfonts/39F43F_2_0.woff2') format('woff2'),url('webfonts/39F43F_2_0.woff') format('woff'),url('webfonts/39F43F_2_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'HelveticaNowText-Bold';src: url('webfonts/39F43F_3_0.eot');src: url('webfonts/39F43F_3_0.eot?#iefix') format('embedded-opentype'),url('webfonts/39F43F_3_0.woff2') format('woff2'),url('webfonts/39F43F_3_0.woff') format('woff'),url('webfonts/39F43F_3_0.ttf') format('truetype');}
 