:root{
  font-size:10px;
  font-family: 'Roboto', sans-serif;
}

@mixin css-reset(){
  padding:0;
  margin:0;
  font-size:1rem;
  font-weight:normal;
  list-style-type:none;
}
@mixin link-reset(){
  text-decoration: none;
}
@mixin image-states(){
  transition:.5s;
  opacity:1;
  &:hover{
    opacity:.7;
  }
}

$breakpoints:(
  small: 320px,
  medium: 768px,
  large: 1026px
);

@mixin breakpoint($mq-breakpoint){
  @if map-has-key($breakpoints,$mq-breakpoint){
    $mq-breakpoint: map-get($breakpoints,$mq-breakpoint)
  }
  @media screen and (min-width: #{$mq-breakpoint}){
    @content;
  }
}

$primary-color:white;
$secondary-color:darken($primary-color,40%);
// $highlight-color:#33FFDD;
$highlight-color:darken($primary-color,40%);

// $theme-color:darken($primary-color,70%);
// $theme-color-hover:darken($primary-color,80%);

// $theme-color:darken(#18a0fb,0%);
// $theme-color-hover:darken(#18a0fb,20%);
$theme-color:darken(#1267fb,0%);
$theme-color-hover:darken(#1267fb,15%);

$roboto: 'Roboto', sans-serif;
$noto: 'Noto Serif TC', serif;
$notoBody: 'Noto Serif', serif;

$type-ramp:(
  xs: 1.2rem,
  sm: 1.4rem,
  md: 1.6rem,
  md-mobile:2rem,
  lg: 2.4rem,
  xl: 3.4rem,
);

$leading-ramp: (
  s:1.25em,
  m:1.5em,
  l:1.6em
);

$margin-ramp: (
  xxs:10px,
  xs: 15px,
  sm: 20px,
  md: 30px,
  lg: 40px,
  xl: 60px,
  xxl:80px,
  xxxl: 120px
);
