@import url(//hello.myfonts.net/count/39f43f);
@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3798079, 2019-08-16T16:12:49-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: HelveticaNowText-Medium by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/text-medium/
 * 
 * Webfont: HelveticaNowDisplay-XBd by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/display-extrabold/
 * 
 * Webfont: HelveticaNowMicro-Medium by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/micro-medium/
 * 
 * Webfont: HelveticaNowText-Bold by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/text-bold/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3798079
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2019 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2019 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'HelveticaNowText-Medium';
  src: url(/static/media/39F43F_0_0.c57e2db2.eot);
  src: url(/static/media/39F43F_0_0.c57e2db2.eot?#iefix) format("embedded-opentype"), url(/static/media/39F43F_0_0.83f81b42.woff2) format("woff2"), url(/static/media/39F43F_0_0.afb0b0ad.woff) format("woff"), url(/static/media/39F43F_0_0.751bac9d.ttf) format("truetype"); }

@font-face {
  font-family: 'HelveticaNowDisplay-XBd';
  src: url(/static/media/39F43F_1_0.025a0368.eot);
  src: url(/static/media/39F43F_1_0.025a0368.eot?#iefix) format("embedded-opentype"), url(/static/media/39F43F_1_0.d469f187.woff2) format("woff2"), url(/static/media/39F43F_1_0.a4b4e33b.woff) format("woff"), url(/static/media/39F43F_1_0.702d19a6.ttf) format("truetype"); }

@font-face {
  font-family: 'HelveticaNowMicro-Medium';
  src: url(/static/media/39F43F_2_0.00e84e1a.eot);
  src: url(/static/media/39F43F_2_0.00e84e1a.eot?#iefix) format("embedded-opentype"), url(/static/media/39F43F_2_0.c582c73a.woff2) format("woff2"), url(/static/media/39F43F_2_0.0031994d.woff) format("woff"), url(/static/media/39F43F_2_0.21d63588.ttf) format("truetype"); }

@font-face {
  font-family: 'HelveticaNowText-Bold';
  src: url(/static/media/39F43F_3_0.91864731.eot);
  src: url(/static/media/39F43F_3_0.91864731.eot?#iefix) format("embedded-opentype"), url(/static/media/39F43F_3_0.79fa6ec3.woff2) format("woff2"), url(/static/media/39F43F_3_0.20db61bc.woff) format("woff"), url(/static/media/39F43F_3_0.d65b6bed.ttf) format("truetype"); }

:root {
  font-size: 10px;
  font-family: 'Roboto', sans-serif; }

.col-1 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-1 {
      -webkit-flex: 0 1 12%;
              flex: 0 1 12%; } }

.col-2 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-2 {
      -webkit-flex: 0 1 28%;
              flex: 0 1 28%; } }

.col-3 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-3 {
      -webkit-flex: 0 1 44%;
              flex: 0 1 44%; } }

.col-4 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-4 {
      -webkit-flex: 0 1 60%;
              flex: 0 1 60%; } }

.col-5 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-5 {
      -webkit-flex: 0 1 76%;
              flex: 0 1 76%; } }

.col-6 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-6 {
      -webkit-flex: 0 1 92%;
              flex: 0 1 92%; } }

.grid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  max-width: 1600px;
  margin: auto;
  position: relative;
  z-index: 1; }
  .grid .splash {
    color: white; }
  .grid .splash__facts {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    list-style-type: none;
    font-size: 1.3em;
    font-family: HelveticaNowText-Bold;
    line-height: 1.5em;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-top: 180px;
    border-top: 1px solid gray; }
    @media screen and (min-width: 768px) {
      .grid .splash__facts {
        -webkit-flex-direction: row nowrap;
                flex-direction: row nowrap;
        font-size: 1.6rem;
        margin-top: 240px; } }
    .grid .splash__facts li {
      max-width: 100%;
      margin-right: 10%;
      margin-top: 20px; }
      @media screen and (min-width: 768px) {
        .grid .splash__facts li {
          max-width: 28%;
          margin-right: 4%; } }
      .grid .splash__facts li:last-child {
        margin-right: 0; }
        @media screen and (min-width: 768px) {
          .grid .splash__facts li:last-child {
            margin-left: auto;
            max-width: 28%; } }
  .grid .splash__header {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    font-family: HelveticaNowDisplay-XBd;
    font-size: 6.8rem;
    margin-top: 60px;
    margin-bottom: 30px;
    line-height: .85em; }
    @media screen and (min-width: 1026px) {
      .grid .splash__header {
        font-size: 260px;
        margin-bottom: 0; } }
  .grid .ProjectOverview {
    color: white;
    width: 100%;
    font-size: 1.3em;
    font-family: HelveticaNowMicro-Medium;
    line-height: 1.5em;
    margin: 15px 0 60px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap; }
    .grid .ProjectOverview__Column {
      width: 92%;
      margin-bottom: 20px;
      margin-left: 4%; }
      @media screen and (min-width: 768px) {
        .grid .ProjectOverview__Column {
          width: 44%; } }
      @media screen and (min-width: 1026px) {
        .grid .ProjectOverview__Column {
          width: 28%; } }
  .grid .ProjectBreakdown {
    color: white;
    width: 100%;
    font-size: 1.6rem;
    letter-spacing: .025em;
    line-height: 1.5em;
    margin: 60px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap; }
    .grid .ProjectBreakdown__Column {
      width: 92%;
      margin-bottom: 20px;
      margin-left: 4%; }
      @media screen and (min-width: 768px) {
        .grid .ProjectBreakdown__Column {
          width: 44%; } }
      @media screen and (min-width: 1026px) {
        .grid .ProjectBreakdown__Column {
          width: 28%; } }
  .grid .grid__quote {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    max-width: 100%;
    font-size: 2.4rem;
    color: white;
    font-family: HelveticaNowDisplay-XBd;
    margin-top: 60px;
    margin-bottom: 60px;
    line-height: 1.25em; }
    .grid .grid__quote--author {
      color: #999999; }
    .grid .grid__quote.quote__intro {
      color: #999999;
      margin-top: 180px; }
    .grid .grid__quote .quote__intro--title {
      color: white; }
    @media screen and (min-width: 768px) {
      .grid .grid__quote {
        font-size: 2.4rem;
        margin-bottom: 60px; } }
    @media screen and (min-width: 1026px) {
      .grid .grid__quote {
        font-size: 3.4rem;
        margin-top: 80px;
        margin-bottom: 80px; } }
  .grid .grid__item {
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0);
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap; }
    @media screen and (min-width: 768px) {
      .grid .grid__item {
        margin-top: 60px;
        margin-bottom: 60px; } }
    .grid .grid__item.grid__item--empty {
      display: none; }
      @media screen and (min-width: 768px) {
        .grid .grid__item.grid__item--empty {
          display: block; } }
  .grid .item__title {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    font-family: HelveticaNowDisplay-XBd;
    font-size: 2.4rem;
    margin-top: -10px;
    margin-bottom: 20px;
    color: white;
    line-height: 1em;
    float: left; }
    @media screen and (min-width: 1026px) {
      .grid .item__title {
        font-size: 3.4rem;
        margin-top: auto; } }
  .grid .item__subtitle {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    font-weight: normal;
    font-size: 2rem;
    color: #999999;
    line-height: 1.25em;
    margin-bottom: 20px;
    float: left; }
    @media screen and (min-width: 1026px) {
      .grid .item__subtitle {
        font-size: 2.4rem;
        margin-bottom: none; } }
  .grid .item__link {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    font-family: HelveticaNowText-Bold;
    color: white;
    font-size: 2rem;
    transition: .35s;
    display: none; }
    .grid .item__link:hover {
      color: #999999; }
    @media screen and (min-width: 1026px) {
      .grid .item__link {
        font-size: 2.4rem;
        display: block;
        float: right; } }
  .grid .item__image img {
    max-width: 100%;
    height: auto;
    margin-bottom: 30px; }
  .grid .item__image--link {
    transition: .5s;
    opacity: 1; }
    .grid .item__image--link:hover {
      opacity: .7; }
  .grid .noBottom {
    margin-bottom: 0; }
    .grid .noBottom img {
      margin-bottom: 0; }

.navigation {
  width: 100%;
  position: fixed;
  background-color: black;
  border-bottom: 1px solid gray;
  z-index: 5; }
  .navigation__layout {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    display: -webkit-flex;
    display: flex;
    max-width: 1600px;
    margin: auto;
    list-style-type: none;
    font-weight: bold; }
  .navigation__item {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 4%;
    margin-right: 4%;
    font-size: 1.6rem;
    color: white;
    cursor: pointer;
    transition: .35s; }
    .navigation__item:hover {
      color: #999999; }
    .navigation__item :visited,
    .navigation__item :focus {
      color: white; }
    @media screen and (min-width: 1026px) {
      .navigation__item {
        margin-left: 0; } }
  .navigation__item--title {
    margin-right: auto;
    margin-left: 4%;
    color: white; }
    .navigation__item--title:hover {
      color: #999999; }
    .navigation__item--title :visited,
    .navigation__item--title :focus {
      color: white; }

.about {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  font-family: HelveticaNowText-Medium;
  background-color: #0D0D0D;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-y: scroll;
  color: white;
  -webkit-overflow-scrolling: touch; }
  .about--visible {
    visibility: visible;
    transition: .45s;
    background-color: #0D0D0D;
    opacity: 1; }
  .about--hidden {
    visibility: hidden;
    transition: .45s;
    background-color: #0D0D0D;
    opacity: 0; }
  .about__close {
    position: fixed;
    cursor: pointer;
    right: 0;
    top: 0;
    transition: .25s;
    padding: 20px; }
    .about__close:hover {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      transition: .25s;
      opacity: .5; }
    @media screen and (min-width: 768px) {
      .about__close {
        right: 10px;
        top: 10px; } }

.aboutContent {
  display: -webkit-flex;
  display: flex;
  max-width: 1600px;
  padding-top: 4%;
  margin: auto;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap; }
  .aboutContent__intro {
    width: 92%;
    height: auto;
    margin: 0 4%;
    -webkit-order: 2;
            order: 2; }
    @media screen and (min-width: 1026px) {
      .aboutContent__intro {
        -webkit-order: 1;
                order: 1;
        width: 44%; } }
  .aboutContent__img {
    -webkit-order: 1;
            order: 1;
    width: 92%;
    margin: 8% 4% 4%;
    margin-left: auto; }
    @media screen and (min-width: 1026px) {
      .aboutContent__img {
        width: 44%;
        -webkit-order: 2;
                order: 2;
        margin-top: 4%; } }
    .aboutContent__img img {
      max-width: 100%; }
  .aboutContent__title {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    width: 100%;
    color: white;
    font-family: HelveticaNowDisplay-XBd;
    font-size: 5.1rem;
    line-height: .75em;
    margin: 10% 0; }
    @media screen and (min-width: 768px) {
      .aboutContent__title {
        margin-bottom: 60px;
        font-size: 120px; } }
  .aboutContent__quote {
    font-size: 2rem;
    color: white;
    font-family: HelveticaNowDisplay-XBd;
    width: 100%;
    margin-bottom: 40px;
    line-height: 1.25em; }
    @media screen and (min-width: 768px) {
      .aboutContent__quote {
        font-size: 2.4rem; } }
    @media screen and (min-width: 1026px) {
      .aboutContent__quote {
        margin-top: 180px;
        width: 85%; } }
    .aboutContent__quote span {
      color: #999999; }
  .aboutContent__description {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    color: white;
    font-size: 16px;
    margin-bottom: 120px;
    line-height: 1.5em; }
    @media screen and (min-width: 1026px) {
      .aboutContent__description {
        font-size: 18px; } }
  .aboutContent .description__copy {
    width: 100%;
    margin-right: 0; }
    @media screen and (min-width: 1026px) {
      .aboutContent .description__copy {
        width: 85%; } }
  .aboutContent__link {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    text-decoration: none;
    color: #999999;
    font-size: 1.4rem; }

.footer {
  max-width: 1600px;
  margin: auto;
  color: white; }
  .footer .footer__container {
    margin: 4% 4% 8% 4%; }
  .footer .footer__contact {
    font-size: 2rem;
    font-family: HelveticaNowDisplay-XBd;
    line-height: 1.6em;
    text-align: center;
    width: 100%;
    margin: auto;
    margin-bottom: 60px; }
    .footer .footer__contact a {
      text-decoration: none;
      color: white;
      transition: .25s ease-out; }
    .footer .footer__contact a:hover {
      color: #999999; }
    @media screen and (min-width: 768px) {
      .footer .footer__contact {
        font-size: 2.4rem;
        width: 100%; } }
    @media screen and (min-width: 1026px) {
      .footer .footer__contact {
        width: 900px;
        font-size: 3.4rem;
        margin-bottom: 120px; } }
  .footer .footer__byline {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    list-style-type: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: baseline;
            align-items: baseline;
    list-style-type: none;
    font-size: 1.6rem;
    line-height: 1.6em;
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid #999999; }
  .footer .footer__bio {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .footer .footer__bio {
        width: 350px; } }
  .footer .footer__social {
    padding: 0;
    margin-left: 0;
    margin-top: 30px;
    list-style-type: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row wrap;
            flex-direction: row wrap; }
    @media screen and (min-width: 768px) {
      .footer .footer__social {
        margin-top: 0;
        margin-left: auto; } }
    .footer .footer__social li {
      transition: .25s ease-out;
      cursor: pointer; }
    .footer .footer__social li:hover {
      color: #999999; }
    .footer .footer__social li:nth-child(2n),
    .footer .footer__social li:last-child {
      margin-left: 40px; }
  .footer .footer__copyright {
    font-size: 1.6rem;
    color: #999999; }

.projectintro {
  margin-top: 180px;
  margin-bottom: 0;
  color: white;
  font-size: 1.6rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (min-width: 768px) {
    .projectintro {
      margin-bottom: 20px; } }
  .projectintro__header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 60px; }
    @media screen and (min-width: 1026px) {
      .projectintro__header {
        margin-bottom: 120px; } }
  .projectintro__title {
    margin-top: 0;
    font-weight: normal;
    font-size: 5.1rem;
    font-family: HelveticaNowDisplay-XBd;
    margin-bottom: 0;
    line-height: 1em;
    width: 92%;
    margin-left: 4%; }
    @media screen and (min-width: 1026px) {
      .projectintro__title {
        font-size: 7.2rem;
        width: 76%; } }
    .projectintro__title-noOverview {
      margin-bottom: 60px; }
      @media screen and (min-width: 1026px) {
        .projectintro__title-noOverview {
          margin-bottom: 120px; } }
  .projectintro__toggle {
    margin-right: 4%;
    margin-top: 60px;
    margin-left: auto;
    -webkit-align-self: right;
            align-self: right; }
    .projectintro__toggle-show, .projectintro__toggle-hide {
      padding: 0;
      margin: 0;
      font-size: 1rem;
      font-weight: normal;
      list-style-type: none;
      cursor: pointer;
      outline: none;
      border: none;
      background-color: #1267fb;
      border-radius: 28px;
      box-sizing: border-box;
      padding: 8px 16px;
      color: white;
      font-weight: normal;
      font-family: HelveticaNowText-Bold;
      font-size: 1.6rem;
      text-align: center;
      transition: .5s;
      width: 100%; }
      .projectintro__toggle-show :visited,
      .projectintro__toggle-show :focus, .projectintro__toggle-hide :visited,
      .projectintro__toggle-hide :focus {
        background-color: #0347bd; }
      .projectintro__toggle-show:hover, .projectintro__toggle-hide:hover {
        background-color: #0347bd;
        transition: .5s; }
    @media screen and (min-width: 1026px) {
      .projectintro__toggle {
        width: 180px;
        text-align: center;
        margin-top: 0; } }
  .projectintro__challenge, .projectintro__solution {
    width: 92%;
    margin-left: 4%;
    margin-bottom: 30px;
    line-height: 1.5em; }
    @media screen and (min-width: 768px) {
      .projectintro__challenge, .projectintro__solution {
        width: 44%;
        margin-left: 4%;
        margin-bottom: 40px; } }
    @media screen and (min-width: 1026px) {
      .projectintro__challenge, .projectintro__solution {
        width: 28%;
        margin-left: 4%; } }
  .projectintro .challenge__header,
  .projectintro .solution__header {
    font-weight: normal;
    font-size: 26px;
    font-family: HelveticaNowText-Bold;
    margin-bottom: 20px; }
  .projectintro__disciplines {
    width: 92%;
    margin-left: 4%;
    margin-bottom: 30px;
    line-height: 1.5em; }
    @media screen and (min-width: 1026px) {
      .projectintro__disciplines {
        width: 28%;
        margin-left: 4%;
        margin-bottom: 40px; } }
    .projectintro__disciplines li {
      line-height: 1.6em;
      padding: 0; }
    .projectintro__disciplines :first-child {
      text-transform: uppercase;
      letter-spacing: .1em;
      font-family: HelveticaNowDisplay-XBd;
      margin-bottom: 15.6px; }
  .projectintro__overview {
    width: 100%;
    padding: 0;
    margin: 0;
    -webkit-align-items: baseline;
            align-items: baseline;
    list-style-type: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap; }
  .projectintro__disciplines {
    padding: 0;
    list-style-type: none; }

.footer__contact {
  font-size: 2rem;
  font-family: HelveticaNowDisplay-XBd;
  line-height: 1.6em;
  text-align: center;
  width: 100%;
  margin: auto;
  margin-bottom: 60px; }
  .footer__contact a {
    text-decoration: none;
    color: white;
    transition: .25s ease-out; }
  .footer__contact a:hover {
    color: #999999; }
  @media screen and (min-width: 768px) {
    .footer__contact {
      font-size: 2.4rem;
      width: 100%; } }
  @media screen and (min-width: 1026px) {
    .footer__contact {
      width: 900px;
      font-size: 3.4rem;
      margin-bottom: 120px; } }

body {
  margin: 0;
  font-family: HelveticaNowText-Medium;
  background-color: #0D0D0D;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

a {
  text-decoration: none;
  color: inherit; }

