.projectintro{
  margin-top: map-get($margin-ramp,xxxl)*1.5;
  margin-bottom: 0;
  color:$primary-color;
  font-size:map-get($type-ramp,md);
  width:100%;
  margin-left:auto;
  margin-right:auto;
  @include breakpoint(medium){
    margin-bottom: map-get($margin-ramp,sm);
  }
  &__header{
    display:flex;
    flex-flow:row wrap;
    align-items:center;
    margin-bottom:60px;
    @include breakpoint(large){
      margin-bottom:120px;
    }
  }
  &__title{
    margin-top:0;
    font-weight:normal;
    font-size:map-get($type-ramp,xl)*1.5;
    font-family:HelveticaNowDisplay-XBd;
    margin-bottom:0;
    line-height:1em;
    width:92%;
    margin-left:4%;
    @include breakpoint(large){
      font-size:map-get($type-ramp,lg) * 3;
      width:76%;
    }
    &-noOverview{
      margin-bottom:60px;
      @include breakpoint(large){
        margin-bottom:120px;
      }
    }
  }
  &__toggle{
    margin-right:4%;
    margin-top:map-get($margin-ramp,xl);
    margin-left:auto;
    align-self:right;
    &-show,
    &-hide{
      @include css-reset;
      cursor:pointer;
      outline:none;
      border:none;
      background-color:$theme-color;
      border-radius:28px;
      box-sizing:border-box;
      padding:8px 16px;
      color:$primary-color;
      font-weight:normal;
      font-family:HelveticaNowText-Bold;
      font-size:map-get($type-ramp,md);
      text-align:center;
      transition:.5s;
      width:100%;
      :visited,
      :focus,
      {
        background-color:$theme-color-hover;
      }
      &:hover{
        background-color:$theme-color-hover;
        transition:.5s;
      }
    }
    @include breakpoint(large){
      width:180px;
      text-align:center;
      margin-top:0;
    }
  }
  &__challenge,
  &__solution{
    width:92%;
    margin-left:4%;
    margin-bottom:map-get($margin-ramp,md);
    line-height:map-get($leading-ramp,m);
    @include breakpoint(medium){
      width:44%;
      margin-left:4%;
      margin-bottom:map-get($margin-ramp,lg);
    }
    @include breakpoint(large){
      width:28%;
      margin-left:4%;
    }
  }
  .challenge__header,
  .solution__header{
    font-weight:normal;
    font-size:26px;
    font-family:HelveticaNowText-Bold;
    margin-bottom:map-get($margin-ramp,sm);
  }
  &__disciplines{
    width:92%;
    margin-left:4%;
    margin-bottom:map-get($margin-ramp,md);
    line-height:map-get($leading-ramp,m);
    @include breakpoint(large){
      width:28%;
      margin-left:4%;
      margin-bottom:map-get($margin-ramp,lg);
    }
    li{
      line-height:1.6em;
      padding:0;
    }
    :first-child{
      text-transform:uppercase;
      letter-spacing: .1em;
      font-family:HelveticaNowDisplay-XBd;
      margin-bottom:map-get($margin-ramp,sm)*.78;
    }
  }

  &__overview{
    width:100%;
    padding:0;
    margin:0;
    align-items:baseline;
    list-style-type:none;
    display:flex;
    flex-flow:row wrap;
  }
  &__disciplines{
    padding:0;
    list-style-type:none;
  }

}
