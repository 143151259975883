$grid-margin: 4%;
$col-widths: (
  1: 12%,
  2: 28%,
  3: 44%,
  4: 60%,
  5: 76%,
  6: 92%
);

$grid__cols: 6;

@for $i from 1 through $grid__cols{
  .col-#{$i}{
    margin-left:$grid-margin;
    flex:0 1 92%;
    @include breakpoint(medium){
      flex:0 1 map-get($col-widths,$i);
    };
  }
};

.grid{
  display:flex;
  flex-flow: row wrap;
  align-items: flex-start;
  max-width:1600px;
  margin:auto;
  position:relative;
  z-index:1;
  .splash{
    color: $primary-color;
  }
  .splash__facts{
    @include css-reset;
    list-style-type:none;
    font-size:1.3em;
    font-family:HelveticaNowText-Bold;
    line-height:map-get($leading-ramp,m);
    width:100%;
    display:flex;
    flex-flow:row wrap;
    margin-top:map-get($margin-ramp,xxxl)*1.5;
    border-top: 1px solid darken($primary-color,50%);
    @include breakpoint(medium){
      flex-direction:row nowrap;
      font-size:map-get($type-ramp,md);
      margin-top:map-get($margin-ramp,xxxl)*2;
    }
    li{
      max-width:100%;
      margin-right:$grid-margin*2.5;
      margin-top:map-get($margin-ramp,sm);
      @include breakpoint(medium){
        max-width:28%;
        margin-right:$grid-margin;
      }
      &:last-child{
        @include breakpoint(medium){
          margin-left:auto;
          max-width:28%;
        }
        margin-right:0;
      }
    }
  }
  .splash__header{
    @include css-reset;
    font-family:HelveticaNowDisplay-XBd;
    font-size:map-get($type-ramp,xl)*2;
    margin-top:map-get($margin-ramp,xl);
    margin-bottom:map-get($margin-ramp,md);
    line-height:.85em;
    @include breakpoint(large){
      font-size:260px;
      margin-bottom:0;
    }
  }
  .ProjectOverview{
    color:white;
    width:100%;
    font-size:1.3em;
    font-family:HelveticaNowMicro-Medium;
    line-height: map-get($leading-ramp,m);
    margin:map-get($margin-ramp,xs) 0 map-get($margin-ramp,xl) 0;
    display:flex;
    flex-flow:row wrap;
    &__Column{
      width:92%;
      margin-bottom:20px;
      margin-left:4%;
      @include breakpoint(medium){
        width:44%;
      }
      @include breakpoint(large){
        width:28%;
      }
    }
  }
  .ProjectBreakdown{
    color:white;
    width:100%;
    font-size:map-get($type-ramp,md);
    letter-spacing:.025em;
    line-height: map-get($leading-ramp,m);
    margin:map-get($margin-ramp,xl) 0;
    display:flex;
    flex-flow:row wrap;
    &__Column{
      width:92%;
      margin-bottom:20px;
      margin-left:4%;
      @include breakpoint(medium){
        width:44%;
      }
      @include breakpoint(large){
        width:28%;
      }
    }
  }
  .grid__quote{
    @include css-reset;
    max-width:100%;
    font-size:map-get($type-ramp,lg);
    color:$primary-color;
    font-family:HelveticaNowDisplay-XBd;
    margin-top:map-get($margin-ramp,xl);
    margin-bottom:map-get($margin-ramp,xl);
    line-height: map-get($leading-ramp,s);
    &--author{
      color:$secondary-color;
    }
    &.quote__intro{
      color:$secondary-color;
      margin-top:map-get($margin-ramp,xxxl)*1.5;
    }
    .quote__intro--title{
      color:$primary-color;
    }
    @include breakpoint(medium){
      font-size:map-get($type-ramp,lg);
      margin-bottom:map-get($margin-ramp,xl);
    }
    @include breakpoint(large){
      font-size:map-get($type-ramp,xl);
      margin-top:map-get($margin-ramp,xxl);
      margin-bottom:map-get($margin-ramp,xxl);
    }
  }
  .grid__item{
    // cursor:pointer;
    max-width: 100%;
    background-color: rgba(0,0,0,0);
    height:auto;
    margin-top:map-get($margin-ramp,xs);
    margin-bottom:map-get($margin-ramp,xs);
    display:flex;
    flex-flow:column wrap;
    @include breakpoint(medium){
      margin-top:map-get($margin-ramp,xl);
      margin-bottom:map-get($margin-ramp,xl);
    }
    &.grid__item--empty{
      display:none;
      @include breakpoint(medium){
        display:block;
      }
    }
  }
  .item__title{
    @include css-reset;
    font-family:HelveticaNowDisplay-XBd;
    font-size:map-get($type-ramp,lg);
    margin-top:-10px;
    margin-bottom:map-get($margin-ramp,sm);
    color:$primary-color;
    line-height:1em;
    float:left;
    @include breakpoint(large){
      font-size:map-get($type-ramp,xl);
      margin-top:auto;
    }
  }
  .item__subtitle{
    @include css-reset;
    font-weight:normal;
    font-size:map-get($type-ramp,md-mobile);
    color:$secondary-color;
    line-height:map-get($leading-ramp,s);
    margin-bottom:map-get($margin-ramp,sm);
    float:left;
    @include breakpoint(large){
      font-size:map-get($type-ramp,lg);
      margin-bottom:none;;
    }
  }
  .item__link{
    @include css-reset;
    font-family:HelveticaNowText-Bold;
    color:$primary-color;
    font-size:map-get($type-ramp,md-mobile);
    transition:.35s;
    display:none;
    &:hover{
      color:$secondary-color;
    }
    @include breakpoint(large){
      font-size:map-get($type-ramp,lg);
      display:block;
      float:right;
    }
  }
  // .item__image{
  //   cursor:pointer;
  // }
  .item__image img{
    max-width:100%;
    height:auto;
    margin-bottom:map-get($margin-ramp,md);
    // @include image-states;
  }
  .item__image--link{
    @include image-states;
  }
  .noBottom{
    margin-bottom:0;
    img{
      margin-bottom:0;
    }
  }
}
