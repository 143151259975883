.about{
  position:fixed;
  z-index:10;
  width:100%;
  height:100vh;
  left:0;
  top:0;
  font-family: HelveticaNowText-Medium;
  background-color:#0D0D0D;
 -moz-osx-font-smoothing: grayscale;
 -webkit-font-smoothing: antialiased;
  overflow-y:scroll;
  color:$primary-color;
  -webkit-overflow-scrolling: touch;
  &--visible{
    visibility: visible;
    transition:.45s;
    background-color:#0D0D0D;
    opacity:1;
  }
  &--hidden{
    visibility: hidden;
    transition:.45s;
    background-color:#0D0D0D;
    opacity:0;
  }
  &__close{
    position:fixed;
    cursor:pointer;
    right:0;
    top:0;
    transition:.25s;
    padding:20px;
    &:hover{
      transform:rotate(90deg);
      transition:.25s;
      opacity:.5;
    }
    @include breakpoint(medium){
      right:10px;
      top:10px;
    }
  }
}
.aboutContent{
  display:flex;
  max-width:1600px;
  padding-top:4%;
  margin:auto;
  flex-flow: row wrap;
  &__intro{
    // display:flex;
    // flex-flow:row wrap;
    width:92%;
    height:auto;
    margin:0 4%;
    order:2;
    @include breakpoint(large){
      order:1;
      width:44%;
    }
  }
  &__img{
    order:1;
    width:92%;
    margin:8% 4% 4%;
    margin-left:auto;
    @include breakpoint(large){
      width:44%;
      order:2;
      margin-top:4%;
    }
    img{
      max-width:100%;
    }
  }
  &__title{
    @include css-reset;
    width:100%;
    color:$primary-color;
    font-family:HelveticaNowDisplay-XBd;
    font-size:map-get($type-ramp,xl)*1.5;
    line-height: .75em;
    margin:10% 0;
    @include breakpoint(medium){
      margin-bottom:map-get($margin-ramp,xl);
      font-size:120px;
    }
    // @include breakpoint(large){
    //   width:46%;
    // }
  }
  &__quote{
    font-size:map-get($type-ramp,md-mobile);
    color:$primary-color;
    font-family:HelveticaNowDisplay-XBd;
    width:100%;
    margin-bottom:map-get($margin-ramp,lg);
    line-height: map-get($leading-ramp,s);
    @include breakpoint(medium){
      font-size:map-get($type-ramp,lg);
    }
    @include breakpoint(large){
      margin-top:map-get($margin-ramp,xxxl)*1.5;
      width:85%;
      // font-size:map-get($type-ramp,xl);
      // max-width:80%;
    }
    span{
      color:$secondary-color;
    }
  }
  &__description{
    @include css-reset;
    color:$primary-color;
    font-size:16px;
    margin-bottom:map-get($margin-ramp,xxxl);
    line-height:map-get($leading-ramp,m);
    @include breakpoint(large){
      font-size:18px;
    }
  }
  .description__copy{
    width:100%;
    margin-right:0;
    @include breakpoint(large){
      width:85%;
    }
  }
  &__link{
    @include css-reset;
    text-decoration:none;
    color:$highlight-color;
    font-size:map-get($type-ramp,sm)
  }
}
