.navigation{
  width:100%;
  position:fixed;
  background-color: black;
  border-bottom: 1px solid darken($primary-color,50%);
  z-index:5;
  &__layout{
    @include css-reset;
    display:flex;
    max-width:1600px;
    margin:auto;
    list-style-type: none;
    font-weight:bold;
  }
  &__item{
    padding-top:20px;
    padding-bottom:20px;
    margin-left:4%;
    margin-right:4%;
    font-size: map-get($type-ramp,md);
    color: $primary-color;
    cursor:pointer;
    transition:.35s;
    &:hover{
      color:$secondary-color;
    }
    :visited,
    :focus
    {
      color:$primary-color;
    }
    @include breakpoint(large){
      margin-left:0;
    }
  }
  &__item--title{
    margin-right:auto;
    margin-left:4%;
    color:$primary-color;
    &:hover{
      color:$secondary-color;
    }
    :visited,
    :focus
    {
      color:$primary-color;
    }
  }
}
